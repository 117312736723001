import { RECALL_CLASSIFICATIONS,  RECALL_STATUS,OUTPUT_DATE_FORMAT } from '../../../misc/commons'
import DocumentViewModel from '../../../components/part/shared/DocumentViewModel'
import ActionType from '../../../components/part/provider/ActionType'
import delegateService from '../../../services/provider/delegates'
import _ from 'lodash'
export default {
    components: {
        DocumentViewModel,
        ActionType,
    },
    data() {
        return {
            recall_classifications: RECALL_CLASSIFICATIONS,
            recall_status: RECALL_STATUS,
            OUTPUT_DATE_FORMAT: OUTPUT_DATE_FORMAT,
            cnt: 1,
            isExpanded: true,
            isOlderExpanded: false,
            internalActions: null,
            organizationId: null,
            providerRecallId: null,
            recallId: null,
            fileType: null,
            fileName: null,
            extension: null,
            signed_url: null,
            preview_url: null,
            responder: {
                Id: null,
                providerRecallId: null,
                firstName: null,
                lastName: null,
                status: null,

            },
            moreLess: 'See Less Information',
            item: {
                'id': '9a1e27a7-f09f-4c9b-b11e-1fade1720627',
                'status': 'OPEN',
                'name': 'qaqaqa',
                'recallsitename': 'Corporate',
                'recallsitecount': 0,
                'recallId': '[]{}<>\\:/+_()qa1234',
                'responseEmail': null,
                'type': 'Removal',
                'date': '2022-05-30T00:00:00',
                'zNumber': null,
                'classification': 'CLASSII',
                'delegatestatus': 'PENDING',
                'isUrgent': false,
                'isCustomizedContent': true,
                'markAs': 'Supplier Voluntary',
                'categories': [
                    'Blood Products'
                ],
                'description': '<p>qa</p>',
                'riskToHealth': '<p>qa</p>',
                'alphaCode': '11053022C',
                'createdBy': 'SupplierTest1 1',
                'thumbnailUrl': null,
                'supplier_name': 'SUP_TEST_1234567890_0987654321',
                'acknowledge_at': '2022-05-30T10:43:23.863',
                'completed_at': '2022-06-02T06:48:06.66',
                'closed_at': null,
                'open_at': '2022-05-30T06:49:17.25',
                'provider_name': 'SUP_TEST_1234567890_0987654321',
                'documents': [{'id': 2038,'recall_id': '9a1e27a7-f09f-4c9b-b11e-1fade1720627','name': 'BD Vacutainer Blood Collection Tubes - US Substitute Options_April 2022','size': 9617,'path': '','extension': '.xlsx','type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','note': null,'is_thumbnail': false},{'id': 2039,'recall_id': '9a1e27a7-f09f-4c9b-b11e-1fade1720627','name': '1511-US-0522-BD_Vacutainer_Blood_Collection_Tubes_Substitutes_US_Region-April2022','size': 5260,'path': '','extension': '.pdf','type': 'application/pdf','note': null,'is_thumbnail': false}],
                'products': [
                    {
                        'id': 2504,
                        'recall_id': '9a1e27a7-f09f-4c9b-b11e-1fade1720627',
                        'description': '<p>EDTA Tubes</p>',
                        'sequence_number': 1,
                        'fields': [
                            {
                                'product_id': 2504,
                                'field_name': 'Lot Numbers',
                                'type': 'Text',
                                'value': {
                                    'valueText': '367835'
                                },
                                'sequence_number': 2,
                                'row_number': 0,
                                'is_unique_identifier': true,
                                'options': [
                                    {
                                        'value': '367835',
                                        'text': '367835',
                                        'rowNumber': 1
                                    },
                                    {
                                        'value': '368589',
                                        'text': '368589',
                                        'rowNumber': 1
                                    },
                                    {
                                        'value': '367844',
                                        'text': '367844',
                                        'rowNumber': 1
                                    },
                                    {
                                        'value': '368661',
                                        'text': '368661',
                                        'rowNumber': 1
                                    }
                                ],
                                'selected_value': '367835'
                            }
                        ],
                        'uniqueFields': [
                            {
                                'product_id': 2504,
                                'field_name': 'Catalog Number',
                                'type': null,
                                'value': {
                                    'valueText': '133'
                                },
                                'sequence_number': 1,
                                'row_number': 1,
                                'is_unique_identifier': false,
                                'options': null,
                                'selected_value': null
                            },
                        ],
                        'createdDatetime': '2022-05-30T06:48:25.993',
                    },
                    {
                        'id': 2505,
                        'recall_id': '9a1e27a7-f09f-4c9b-b11e-1fade1720627',
                        'description': '<p>Glucose Tubes</p>',
                        'sequence_number': 2,
                        'fields': [
                            {
                                'product_id': 2505,
                                'field_name': 'Lot Numbers',
                                'type': 'Text',
                                'value': {
                                    'valueText': '368587'
                                },
                                'sequence_number': 2,
                                'row_number': 0,
                                'is_unique_identifier': true,
                                'options': [
                                    {
                                        'value': '368587',
                                        'text': '368587',
                                        'rowNumber': 1
                                    }
                                ],
                                'selected_value': '368587'
                            }
                        ],
                        'uniqueFields': [
                            {
                                'product_id': 2505,
                                'field_name': 'Lot Numbers',
                                'type': null,
                                'value': {
                                    'valueText': '368587'
                                },
                                'sequence_number': 2,
                                'row_number': 1,
                                'is_unique_identifier': false,
                                'options': null,
                                'selected_value': null
                            },
                        ],
                        'createdDatetime': '2022-05-30T06:48:25.993',
                    },
                    {
                        'id': 2506,
                        'recall_id': '9a1e27a7-f09f-4c9b-b11e-1fade1720627',
                        'description': '<p>PST Tubes</p>',
                        'sequence_number': 3,
                        'fields': [
                            {
                                'product_id': 2506,
                                'field_name': 'Lot Numbers',
                                'type': 'Text',
                                'value': {
                                    'valueText': '368056'
                                },
                                'sequence_number': 2,
                                'row_number': 0,
                                'is_unique_identifier': true,
                                'options': [
                                    {
                                        'value': '368056',
                                        'text': '368056',
                                        'rowNumber': 1
                                    }
                                ],
                                'selected_value': '368056'
                            }
                        ],
                        'uniqueFields': [
                            {
                                'product_id': 2506,
                                'field_name': 'Lot Numbers',
                                'type': null,
                                'value': {
                                    'valueText': '368056'
                                },
                                'sequence_number': 3,
                                'row_number': 1,
                                'is_unique_identifier': false,
                                'options': null,
                                'selected_value': null
                            },
                        ],
                        'createdDatetime': '2022-05-30T06:48:25.993',
                    },
                    {
                        'id': 2506,
                        'recall_id': '9a1e27a7-f09f-4c9b-b11e-1fade1720627',
                        'description': '<p>SST Tubes</p>',
                        'sequence_number': 3,
                        'fields': [
                            {
                                'product_id': 2506,
                                'field_name': 'Lot Numbers',
                                'type': 'Text',
                                'value': {
                                    'valueText': '367981'
                                },
                                'sequence_number': 2,
                                'row_number': 0,
                                'is_unique_identifier': true,
                                'options': [
                                    {
                                        'value': '367981',
                                        'text': '367981',
                                        'rowNumber': 1
                                    },
                                    {
                                        'value': '367987',
                                        'text': '367987',
                                        'rowNumber': 1
                                    }
                                ],
                                'selected_value': '367981'
                            }
                        ],
                        'uniqueFields': [
                            {
                                'product_id': 2506,
                                'field_name': 'Lot Numbers',
                                'type': null,
                                'value': {
                                    'valueText': '367981'
                                },
                                'sequence_number': 3,
                                'row_number': 1,
                                'is_unique_identifier': false,
                                'options': null,
                                'selected_value': null
                            },
                        ],
                        'createdDatetime': '2022-05-30T06:48:25.993',
                    }
                ],
                'actions': [
                    {
                        'id': 171,
                        'baseActionId': 0,
                        'description': 'Please acknowledge that you have received this backorder notice.',
                        'action_type': 'Checkbox',
                        'sequence_number': 1,
                        'childactioncount': 0,
                        'is_internal_action': false,
                        'response_data': {},
                        'isAllowOnlyOne': false,
                        'actionValues': [],
                        'uniqueProductList': null,
                        'multiSelectedValues': [],
                        'singleSelectedValues': null,
                        'displayValue': null,
                        'isCustomActionType': false,
                        'isExpanded': false
                    },
                    {
                        'id': 238,
                        'baseActionId': 0,
                        'description': 'Please follow outlined allocation process until further notice.',
                        'action_type': 'Checkbox',
                        'sequence_number': 1,
                        'childactioncount': 0,
                        'is_internal_action': false,
                        'response_data': {},
                        'isAllowOnlyOne': false,
                        'actionValues': [],
                        'uniqueProductList': null,
                        'multiSelectedValues': [],
                        'singleSelectedValues': null,
                        'displayValue': null,
                        'isCustomActionType': false,
                        'isExpanded': false
                    }
                ],
            }
        }
    },
    created(){
        this.internalActions = this.item.actions
        for (let i = 0; i < this.internalActions.length; i++) {
            let List = []
            if (this.internalActions[i].action_type == 'MultipleChoice') {
                for (let j = 0; j < this.internalActions[i].actionValues.length; j++) {
                    List.push({ value: this.cnt, text: this.internalActions[i].actionValues[j].value, })
                    if (!this.internalActions[i].isAllowOnlyOne) {
                        this.internalActions[i].response_data.valueMultiChoice.forEach((element) => {
                            if (element.valueMultiChoice == this.internalActions[i].actionValues[j].value) {
                                this.internalActions[i].multiSelectedValues.push(this.cnt)
                            }
                        })
                    } else {
                        if (this.internalActions[i].response_data.valueMultiChoice.length > 0) {
                            if (this.internalActions[i].response_data.valueMultiChoice[0].valueMultiChoice == this.internalActions[i].actionValues[j].value) {
                                this.internalActions[i].singleSelectedValues = this.cnt
                            }
                        }
                    }
                    this.cnt++
                }
                this.internalActions[i].actionValues = []
                this.internalActions[i].actionValues = List
            }
        }
    },
    methods: {

        expandMoreLess() {
            if (this.isExpanded == false) this.moreLess = 'See Less Information'
            else this.moreLess = 'See More Information'
        },
        async save() {
            this.responder.status = 'RESPONDED'
        },
        saveAsDraft() {
            this.responder.status = 'DRAFT'

        },
        forceRerenderView(extension,fileName) {
            this.fileName=fileName
            this.getDocumentById(extension,false).then(() => {
                this.$refs.modal.show()
            })
        },
        download(extension,fileName) {
            this.fileName=fileName
            this.getDocumentById(extension, true).then(resp => {
                if (this.signed_url) {
                    this.openExternalLink(this.signed_url)
                } else {
                    this._showToast('Nothing to download', { variant: 'danger' })
                }
            })
        },
        getDocumentById(extension,isDownload) {
            return new Promise((resolve, reject) => {
                delegateService.StaticFile(extension, isDownload).then(resp => {
                    if (!resp.error) {
                        if(isDownload){
                            this.signed_url = resp.data.d
                        } else{
                            if(extension=='doc'){
                                this.preview_url='https://view.officeapps.live.com/op/embed.aspx?src='+ encodeURIComponent(resp.data.d)
                            }
                            else if(extension=='pdf'){
                                this.preview_url = resp.data.d
                            }
                            else if(extension=='xlsx'){
                                this.preview_url = 'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(resp.data.d)

                            }

                        }

                        this.fileType=extension
                        resolve()
                    } else {
                        reject()
                    }
                })
            })
        }
    },
}